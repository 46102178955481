import { isProdDeployment } from "../src/utilities";

const setPXParam2 = function( ecid ) {

	/** Passing adobe ECID as second parameter to perimeterx **/
	window._pxParam2 = ecid;

}

const init = () => {
	return new Promise( ( resolve, reject ) => {

		// set PROD or DEV ID
		window._pxAppId = ( isProdDeployment ? 'PXJ770cP7Y' : 'PX04KmKlXI' );

		// Register Async Event for hashed score
		window[ window._pxAppId + '_asyncInit' ] = function ( px ) {
			px.Events.on( 'score', function ( score ) {
				// Set your action for the score here - can go to another variable or function. In this case we just log it to the console.
				try {
					_aape.pxbs = score;
				}
				catch ( e ) {
					console.log( LOG_PREFIX + " -- PX error: ", e );
				}
			} );

			//enrichment function
			px.Events.on( 'enrich', function ( value ) {
				// Set your action for the score here - can go to another variable or function. In this case we just log it to the console.
				try {
					const base64Data = ( typeof value === "string" && value.split ) ? value.split( ":" )[ 1 ] : null; // split value to get the base64 encoded data
					if ( base64Data ) {
						const dataStr = atob( base64Data ); // base64 decode the enrichment data
						const data = JSON.parse( dataStr ); // get the data as JSON
						_aape.pxEnrich = data.inc_id;
					}
				}
				catch ( e ) {
					console.log( LOG_PREFIX + " -- PX Error: ", e );
				}
			} );

		};

		/**
		 * going to pass '1' to px as the 3 param if the customer lands on the confirmation page for better tracking
		 */
		if ( location.pathname === "/mycheckout/thankyou" ) {
			window._pxParam3 = '1';
		}

		let h = document.getElementsByTagName( "script" )[ 0 ],
			s = document.createElement( "script" );
		s.async = 1;
		s.src = "//client.px-cloud.net/" + window._pxAppId + "/main.min.js";
		h.insertBefore( s, h.firstChild );
		s.onload = () => resolve( console.log( LOG_PREFIX + " -- PX script loaded. ") );
		s.onerror = () => reject( new Error( LOG_PREFIX + ` -- PX failed to load script: ${ s.src }` ) );

	} );

};


export {
	init,
	setPXParam2
}
